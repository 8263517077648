import React from "react";
import { Flex } from "@lions/ui-components";
import { TypographyColor } from "../TypographyColor";
import { AskResponseSource } from "@/services/providers/llm/types";
import { CardData } from "@/renderers";
import { CardComponent } from "../CardComponent";
import {
  campaignToCard,
  entryToCard,
  inspirationToCard,
  talkToCard,
} from "@/services/libs";
import {
  Campaign,
  Entry,
  Inspiration,
  Talk,
} from "@/services/providers/gemini/types";

const sourcesBlockStyle = {
  width: "266px",
};

export const Sources = ({ sources }: ISourcesProps) => (
  <Flex style={sourcesBlockStyle} flexDir={"column"}>
    <TypographyColor as="h3" color="black" size="label-caption">
      Sources
    </TypographyColor>
    {sources.map((source) => {
      const cardData = sourceToCardData(source);
      return (
        cardData && (
          <CardComponent
            {...{ ...cardData, media: undefined }}
            key={source.contentId}
          />
        )
      );
    })}
  </Flex>
);

interface ISourcesProps {
  sources: AskResponseSource[];
}

const sourceToCardData = (source: AskResponseSource): CardData | null => {
  if (!source.content || !source.sourceType) return null;
  switch (source.sourceType) {
    case "entry":
      return entryToCard(source.content as Entry);
    case "campaign":
      return campaignToCard(source.content as Campaign);
    case "talk":
      return talkToCard(source.content as Talk);
    case "inspiration":
      return inspirationToCard(source.content as Inspiration);
    default:
      return null;
  }
};
