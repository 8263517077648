import { PromptSuggestionsData } from "@/components";
import { CaptionComponent } from "@/components/CaptionComponent";
import { Button, Group } from "@lions/ui-components";
import { Spacer } from "@horizon/components";
import { Events, PubSubProvider } from "@/libs/pubSub";
import { Disclaimer } from "./AiDisclaimer";

export const PromptSuggestionsComponent = ({
  suggestions,
  label,
}: PromptSuggestionsData) => {
  const haveSuggestions = suggestions && suggestions.length > 0;
  return (
    <Spacer size="">
      {haveSuggestions && (
        <CaptionComponent content={label} type="Caption" size="medium" />
      )}
      {haveSuggestions && (
        <Group
          preset={{ maxComponentsPerRow: 2, name: "default" }}
          gridGap="spacing-small"
        >
          {suggestions.map((suggestion) =>
            CTA({ label: suggestion.prompt, id: suggestion.id })
          )}
        </Group>
      )}
      <Disclaimer />
    </Spacer>
  );
};

const CTA = ({ label, id }: { label: string; id: number }) => (
  <Button
    variant={"secondary"}
    size={"sm"}
    accessibility={{ label: label }}
    key={label}
    width="full"
    onClick={() => {
      PubSubProvider.publishEvent(Events.AISearchRequest, {
        id,
        search_text: label,
      });
    }}
  >
    {label}
  </Button>
);
