import {
  CardData,
  ComponentData,
  FilterBlockData,
  ISelectDropdownOption,
  SearchBarDataExtend,
  SearchDidYouMeanData,
  SearchPageTitleData,
  TabContainerData,
  TextData,
} from "@/renderers";
import {
  DidYouMean,
  FilteredContent,
  FilterOption,
  Pagination,
  SearchCounts,
} from "@/services/providers/gemini/types";
import {
  campaignToCard,
  talkToCard,
  editorialToCard,
  inspirationToCard,
} from "@/services/libs";
import {
  Sort,
  TEXT_CONSTS,
  defaultComponentsPerRow,
  formatNumber,
  pluralize,
} from "@/services/libs";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import { AggregatedTab, Tab, Tabs } from "@/types/tab.d";

export type SearchFilteredContent =
  | FilteredContent
  | FilteredContent[]
  | undefined;

export const AGGREGATE_TAB: AggregatedTab = {
  label: "all",
  copy: "All",
  countKey: "totalCount",
};

export const TABS: Tabs = {
  campaigns: {
    label: "campaigns",
    copy: "Campaigns",
    index: 0,
    build: campaignToCard,
    filter: Sort.DEFAULT_SORT_OPTIONS,
    countKey: "campaignCount",
  },
  talks: {
    label: "talks",
    copy: "Talks",
    index: 1,
    build: talkToCard,
    filter: Sort.CLASSIC_SORT_OPTIONS,
    countKey: "talkCount",
  },
  reports: {
    label: "editorials",
    copy: "Reports",
    index: 2,
    build: editorialToCard,
    countKey: "editorialCount",
  },
  inspirations: {
    label: "inspirations",
    copy: "Inspirations",
    index: 3,
    build: inspirationToCard,
    countKey: "inspiraionCount",
  },
};

const getParsedQuery = (parsedQuery: ParsedQuery): boolean => {
  return parsedQuery?.value != null && !Array.isArray(parsedQuery.value);
};

const getCurrentTab = (parsedQuery: ParsedQuery) => {
  let currentTab: Tab | undefined;
  if (getParsedQuery(parsedQuery)) {
    switch (parsedQuery!.value) {
      case "campaigns":
        currentTab = TABS.campaigns;
        break;
      case "talks":
        currentTab = TABS.talks;
        break;
      case "editorials":
        currentTab = TABS.reports;
        break;
      case "inspirations":
        currentTab = TABS.inspirations;
        break;
      default:
        currentTab = undefined;
    }
    return currentTab;
  }
};

const toSearchTitle = (
  searchCounts: SearchCounts,
  parsedQuery: ParsedQuery
): SearchPageTitleData => {
  let content;
  let currentTab: Tab | undefined;
  if (getParsedQuery(parsedQuery)) {
    switch (parsedQuery!.value) {
      case "campaigns":
        currentTab = TABS.campaigns;
        break;
      case "talks":
        currentTab = TABS.talks;
        break;
      case "editorials":
        currentTab = TABS.reports;
        break;
      case "inspirations":
        currentTab = TABS.inspirations;
        break;
      default:
        currentTab = undefined;
    }
  }

  if (currentTab) {
    const countKey = Reflect.get(currentTab, "countKey");
    const count = Reflect.get(searchCounts, countKey);
    content = `${formatNumber(count)} ${pluralize(
      count,
      TEXT_CONSTS.RESULT
    )} found in ${currentTab.copy}`;
  }

  return {
    type: "SearchPageTitle",
    content,
  };
};

const toTabContainer = (
  currentTabQuery: { key: string; value: string } | undefined,
  filteredContent?: SearchFilteredContent,
  parsedQuery?: ParsedQuery,
  filterOptions?: FilterOption[]
): TabContainerData | any | undefined => {
  const currentTab = getCurrentTab(currentTabQuery);
  // SectionBlock
  return filteredContent
    ? {
        type: "Container",
        children: [
          {
            type: "SearchContainer",
            content: getContentForTabs(
              currentTab,
              filteredContent,
              parsedQuery,
              filterOptions
            ),
          },
        ],
      }
    : undefined;
};

const getContentForTabs = (
  currentTab: Tab | undefined,
  filteredContent: SearchFilteredContent,
  parsedQuery: ParsedQuery,
  filterOptions?: FilterOption[]
) => {
  const tabs: Tab[] = currentTab ? [currentTab] : Object.values(TABS);
  const components = tabs.map((tab) =>
    toGrid(tab, filteredContent, parsedQuery, filterOptions)
  );
  // some oddity getting returned as components type is ComponentData[][]
  // need to investigate maybe to do with object values
  return components.flat();
};

const toGrid = (
  tab: Tab,
  filteredContent: SearchFilteredContent,
  parsedQuery?: ParsedQuery,
  filterOptions?: FilterOption[]
): ComponentData[] => {
  if (!filteredContent) return [];

  const componentData = [];

  if (filterOptions) {
    componentData.push(
      addFilterBlockData(
        filterOptions,
        parsedQuery,
        tab.filter ?? undefined
      ) as ComponentData
    );
  }

  const { content, pagination } =
    getFilteredContentFromSearchFilteredContent(filteredContent, tab.index) ??
    {};
  if (content && (pagination?.totalRecords || 0) > 0)
    componentData.push(
      buildComponentGrouping(
        filteredContent,
        tab.copy,
        tab.label,
        content ? content.map((content) => tab.build(content)) : [],
        pagination?.totalRecords ?? 0,
        parsedQuery,
        pagination ?? undefined
      )
    );
  return componentData;
};

const addFilterBlockData = (
  filterOptions: FilterOption[],
  parsedQuery?: ParsedQuery,
  sortOptions?: Sort.SortOption[]
): FilterBlockData | null => {
  return GeminiMapper.toFilterBlockData(
    filterOptions,
    parsedQuery,
    sortOptions
      ? Sort.buildSortOptions(
          sortOptions,
          Sort.parseSortValueFromQuery(parsedQuery)
        )
      : undefined
  );
};

const buildComponentGrouping = (
  filteredContent: SearchFilteredContent,
  tabCopy: string,
  tabLabel: string,
  cardComponents: CardData[],
  totalRecords: number,
  parsedQuery?: ParsedQuery,
  pagination?: Pagination
) => {
  const variant = Array.isArray(filteredContent) ? "carousel" : "grid";
  return {
    type: "ComponentGrouping",
    title:
      variant === "carousel"
        ? `${formatNumber(totalRecords)} ${pluralize(
            totalRecords,
            TEXT_CONSTS.RESULT
          )} found in ${tabCopy}`
        : "",
    variant,
    components: cardComponents,
    componentsPerRow: defaultComponentsPerRow,
    page: pagination?.currentPage,
    pageSize: pagination?.pageSize,
    totalRecords: pagination?.totalRecords,
    cta: buildCTA(filteredContent, parsedQuery, tabLabel),

    ...(pagination ? buildPagination(pagination) : {}),
  } as ComponentData;
};

const buildPagination = (pagination: Pagination) => ({
  currentPage: pagination.currentPage,
  pageSize: pagination.pageSize,
  totalRecords: pagination.totalRecords,
});

const buildCTA = (
  filteredContent: SearchFilteredContent,
  parsedQuery?: ParsedQuery,
  contentType?: string
) => {
  const query = Object.assign({}, parsedQuery);
  if (query) {
    query.content_type = contentType || "all";
  }

  if (Array.isArray(filteredContent)) {
    return {
      label: "View All",
      href: `/search?${QueryParams.queryToString(query)}`,
    };
  }
  return undefined;
};

const getFilteredContentFromSearchFilteredContent = (
  filteredContent: SearchFilteredContent,
  index: number = 0
): FilteredContent | undefined => {
  return Array.isArray(filteredContent)
    ? filteredContent[index]
    : filteredContent;
};

const toEmptyResultsText = (): TextData => ({
  type: "Text",
  content: "UNFORTUNATELY, NO RESULTS WERE FOUND FOR YOUR SEARCH.",
  size: "xxlarge",
});

const createTabOptions = (tab: Tab | AggregatedTab, contentType: string) => {
  return {
    value: tab.label,
    label: tab.copy,
    checked: tab.label === contentType,
  };
};

const createSearchOptions = (
  tabs: Tabs,
  agg: AggregatedTab,
  contentType: string = "all"
): ISelectDropdownOption[] => {
  // can't clone/structured tabs as it has functions ~ chucks a wobbly
  const options = Object.values(tabs).map((tab) => {
    return createTabOptions(tab, contentType);
  });

  const allOptions = createTabOptions(agg, contentType);
  options.unshift(allOptions);

  return options;
};

const toSearchBarData = (
  searchText: string,
  suggestions: string[],
  contentType?: string
): SearchBarDataExtend => {
  const searchOptions = createSearchOptions(TABS, AGGREGATE_TAB, contentType);
  const contentLabelObj = searchOptions.find((item) => item?.checked === true);
  return {
    type: "SearchBar",
    searchText: searchText,
    searchBarLabel: "Search across Campaigns, Talks, Reports, and Inspirations",
    suggestions: suggestions.map((suggestion) => ({ label: suggestion })),
    contentType: contentType,
    searchOptions,
    contentLabel: contentLabelObj?.label,
    searchDrawerActive: true,
  };
};

const toDidYouMean = (
  parsedQuery?: ParsedQuery,
  filteredContent?: SearchFilteredContent
): SearchDidYouMeanData => {
  let hasDidYouMean: DidYouMean | null = null;
  const contentTypeValue = parsedQuery?.content_type
    ? parsedQuery?.content_type
    : "all";

  if (Array.isArray(filteredContent)) {
    const filtered = filteredContent.filter((item) =>
      Reflect.has(item, "didYouMean")
    );
    if (filtered.length > 0) {
      hasDidYouMean = filtered[0].didYouMean as DidYouMean;
    }
  } else {
    hasDidYouMean = filteredContent?.didYouMean
      ? filteredContent.didYouMean
      : null;
  }

  let altQueryObj;
  if (hasDidYouMean) {
    altQueryObj = structuredClone(parsedQuery);
    altQueryObj!.search_text = hasDidYouMean.suggestion;
  }

  const altSearch = altQueryObj?.search_text
    ? {
        label: `${altQueryObj?.search_text as string}`,
        href: `/search?${QueryParams.queryToString(altQueryObj)}`,
      }
    : undefined;

  const currentSearch = parsedQuery?.search_text
    ? {
        label: parsedQuery?.search_text as string,
        href: `/search?${QueryParams.queryToString(parsedQuery)}`,
      }
    : undefined;

  return {
    type: "SearchDidYouMean",
    didYouMean: hasDidYouMean,
    altSearch,
    currentSearch,
    queryValue: contentTypeValue as string,
  };
};

export const SearchMapper = {
  toSearchBarData,
  toTabContainer,
  toEmptyResultsText,
  toSearchTitle,
  toDidYouMean,
};
