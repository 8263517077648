import { Flex } from "@lions/ui-components";
import { NoMarginMarkdownComponent } from "../MarkdownComponent";
import { AiResponseFeedback } from "./AiResponseFeedback";
import markdownit from "markdown-it";

export const Content = ({
  responseId,
  content,
}: {
  responseId: number;
  content: string;
}) => {
  const md = markdownit({ breaks: true });
  const result = md.render(content);
  return (
    <Flex flexDir={"column"} style={contentStyle}>
      <NoMarginMarkdownComponent
        type="Markdown"
        copySize="small"
        html={result}
      />
      <AiResponseFeedback responseId={responseId} />
    </Flex>
  );
};

const contentStyle = {
  width: "fit-content",
  paddingRight: "28px",
};
