import { ITableCell, ITableCellTagVariant, ITableHeader } from "@ui-components";
import { AwardLevel, CampaignEntry } from "../providers/gemini/types";
import { awardLevelToVariant } from "./award_utils";

export const buildHeaderCell = (text: string): ITableHeader => ({
  label: { text, size: "body-regular" },
});
export const buildLabelCell = (text: string): ITableCell => ({
  variant: "label",
  label: { text, size: "body-regular" },
});
export const buildLinkCell = (text: string, href: string): ITableCell => ({
  variant: "link",
  link: { text, href, size: "body-regular" },
});
export const buildTagCell = (
  text: string,
  variant: ITableCellTagVariant
): ITableCell => ({ variant: "tag", tag: { text, variant } });
export const buildHighlightCell = (cell: ITableCell): ITableCell => ({
  ...cell,
  highlight: true,
});
export const buildTagCellFromCampaignEntry = (
  entry: CampaignEntry
): ITableCell => {
  const variant = awardLevelToVariant(entry.level as AwardLevel);
  return variant ? buildTagCell(entry.levelLabel, variant) : buildLabelCell("");
};
