import {
  ComponentData,
  GalleryCarouselData,
  PageTitleBlockData,
  SaveCollectionButtonData,
  TabContainerData,
  TabData,
  TableData,
  TableDataNew,
} from "@/renderers";
import {
  Award,
  AwardLevel,
  awardLevelOrder,
  CampaignEntry,
  Collection,
  Entry,
} from "@/services/providers/gemini/types";
import { listToCommaSeparatedString, typeFromAsset } from "../../libs";
import { ComponentGrouping } from "@/services/providers/contentful/types";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { buildContainerData, buildSpacerData, PATHS } from "@/services/libs";
import { awardToTagData } from "@/services/libs/award_utils";
import { UserAction } from "@/libs/authentication/user";
import {
  buildHeaderCell,
  buildHighlightCell,
  buildLabelCell,
  buildLinkCell,
  buildTagCellFromCampaignEntry,
} from "@/services/libs/table_utils";

const toTitleData = (
  entry: Entry,
  collections?: Collection[]
): PageTitleBlockData => {
  const { brand, campaign, company, entryType, title, year } = entry;
  let awardTags = {};
  if (entryType.award !== null) {
    awardTags = { awardTags: [awardToTagData(entryType.award as Award)] };
  }
  return {
    type: "PageTitleBlock",
    title: title,
    superText: `${entryType?.name} > ${entryType?.section?.name}`,
    subText:
      listToCommaSeparatedString([company?.name, company?.town]) +
      ` / ` +
      brand?.name +
      " / " +
      year,
    ...awardTags,
    ctas: [
      {
        type: "Button",
        variant: "secondary",
        icon: { name: "Layout", position: "end" },
        label: "Campaign",
        href: `${PATHS.campaigns}/${campaign?.slug}`,
      },
    ],
    saveCollectionButtonData: toCollectionsSaveButton(entry, collections),
  };
};

const maybeToGalleryCarousel = (
  entry: Entry,
  userActions?: UserAction[]
): GalleryCarouselData | null =>
  entry.assets.length ? toGalleryCarousel(entry, userActions) : null;

const toGalleryCarousel = (
  { assets }: Entry,
  userActions?: UserAction[]
): GalleryCarouselData => ({
  type: "GalleryCarousel",
  userActions,
  media: assets.map((asset) => ({
    href: asset.fullUrl || "",
    alt: asset.label || "",
    mediaType: typeFromAsset(asset),
    thumbnail: asset.thumbnailUrl,
    id: asset.id as number,
    downloadable: asset.downloadable,
  })),
});

const toTabContainer = (entry: Entry): TabContainerData => {
  const { campaignsOfExecution, slug } = entry;
  let tabs: TabData[];
  tabs = [
    {
      type: "Tab",
      title: "Overview",
      label: "Overview",
      components: [buildContainerData(toOverviewTab(entry))],
    },
    {
      type: "Tab",
      title: "Credits",
      label: "credits",
      components: [buildContainerData([buildSpacerData(toCreditsTab(entry))])],
    },
  ];
  if (campaignsOfExecution?.length) {
    const executionTab: TabData = {
      type: "Tab",
      title: "Executions",
      label: "Executions",
      components: [
        buildContainerData(toExecutionTab(campaignsOfExecution, slug)),
      ],
    };
    tabs.splice(1, 0, executionTab);
  }
  return {
    type: "TabContainer",
    reloadOnTabChange: false,
    tabs: tabs,
  };
};

const toOverviewTab = ({ caseStudy }: Entry): ComponentData[] => {
  const content = caseStudy?.reduce(
    (accumulator: any[], entry) => {
      accumulator.push({
        type: "Caption",
        content: entry?.question,
        size: "medium",
      });
      accumulator.push({
        type: "Markdown",
        html: entry.response
          ?.split("\n")
          .filter(Boolean)
          .map((str) => `<p>${str}</p>`)
          .join(""),
        copySize: "medium",
      });
      return accumulator;
    },
    [
      {
        type: "HeroTitle",
        content: "OVERVIEW",
        size: "medium",
      },
    ]
  );
  return [
    {
      type: "Spacer",
      size: "medium",
      content,
    } as ComponentData,
  ];
};

const toCreditsTab = (entry: Entry): TableData[] => {
  const companies = entry.credits?.companies?.map((company) => {
    return {
      cells: [
        {
          label: {
            copy: company.name,
            href: company.slug ? `${PATHS.agencies}/${company.slug}` : "",
            size: "small",
          },
          type: company.slug ? "link" : "label",
        },
        {
          label: {
            copy: company.location,
            size: "small",
          },
        },
        {
          label: {
            copy: company.role,
            size: "small",
          },
        },
      ],
    };
  });
  const people = entry.credits?.people?.map((person) => {
    return {
      cells: [
        {
          label: {
            copy: person.name,
            href: person.slug ? `${PATHS.people}/${person.slug}` : "",
            size: "small",
          },
          type: person.slug ? "link" : "label",
        },
        {
          label: {
            copy: person.company,
            size: "small",
          },
        },
        {
          label: {
            copy: person.role,
            size: "small",
          },
        },
      ],
    };
  });

  return [
    entry.credits?.companies && {
      type: "Table",
      title: "Companies",
      headers: [
        { label: { copy: "Company" } },
        { label: { copy: "Location" } },
        { label: { copy: "Role" } },
      ],
      rows: companies,
    },
    entry.credits?.people && {
      type: "Table",
      title: "People",
      headers: [
        { label: { copy: "Name" } },
        { label: { copy: "Company" } },
        { label: { copy: "Role" } },
      ],
      rows: people,
    },
  ].filter(Boolean) as TableData[];
};

export const toExecutionTab = (
  campaignsOfExecution: CampaignEntry[],
  slug: string | null,
  title: string = "Executions"
): TableDataNew[] => [
  {
    type: "TableNew",
    title: title,
    tableData: {
      headers: [
        buildHeaderCell("Execution"),
        buildHeaderCell("Section"),
        buildHeaderCell("Category"),
        buildHeaderCell("Prize"),
        buildHeaderCell("Award"),
      ],
      rows: sortCampaignsOfExecutions(campaignsOfExecution).map((campaign) => ({
        cells: [
          slug !== campaign.slug
            ? buildHighlightCell(
                buildLinkCell(
                  campaign.title,
                  `${PATHS.entries}/${campaign.slug}`
                )
              )
            : buildHighlightCell(buildLabelCell(campaign.title)),
          buildLabelCell(campaign.section),
          buildLabelCell(campaign.category),
          buildLabelCell(campaign.prizeLabel ?? ""),
          buildTagCellFromCampaignEntry(campaign),
        ],
      })),
    },
  },
];

const toFooterCarousels = (componentGroupings: ComponentGrouping[]) => {
  return componentGroupings.map(GeminiMapper.toComponentGroupingData);
};

const toCollectionsSaveButton = (
  entry: Entry,
  collections?: Collection[]
): SaveCollectionButtonData | undefined => {
  if (!entry.saveable) return;
  return {
    type: "SaveCollectionButton",
    collections: collections?.map(GeminiMapper.toCollectionData),
    contentType: "entry",
    contentId: entry.id.toString(),
  };
};

const sortCampaignsOfExecutions = (
  campaignsOfExecution: CampaignEntry[]
): CampaignEntry[] => {
  return campaignsOfExecution.sort((a, b) => {
    const levelA = a.levelLabel ? awardLevelOrder[a.level as AwardLevel] : 0;
    const levelB = b.levelLabel ? awardLevelOrder[b.level as AwardLevel] : 0;
    if (levelA > levelB) {
      return -1;
    }
    if (levelA < levelA) {
      return 1;
    }
    return 0;
  });
};

export const EntryShowMapper = {
  toTitleData,
  toGalleryCarousel,
  maybeToGalleryCarousel,
  toTabContainer,
  toFooterCarousels,
};
