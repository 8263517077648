import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, IMessageProps, Modal, Typography } from "@lions/ui-components";
import { TypographyColor } from "../TypographyColor";
import { Api } from "@/libs/api";
import { logger } from "@/libs/Logger";
import { Feedback, FeedbackData } from "@/services/providers/llm/types";
import { MessageFloating } from "../MessageFloating";

type IconState = "Default" | "ThumbDown" | "ThumbUp";
interface IFeedbackOption {
  feedback: Feedback;
  label: string;
}

export const AiResponseFeedback = ({ responseId }: { responseId: number }) => {
  const [iconState, setIconState] = useState<IconState>("Default");
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClick = useCallback(
    (newState: IconState) => {
      if (iconState === newState) return;
      setIconState(newState);
      setModalOpen(true);
    },
    [iconState]
  );

  return (
    <TypographyColor
      as={"div"}
      className="feedback-control"
      color="black"
      size="body-regular"
    >
      <span>Was this helpful?</span>
      <Button
        icon={{ name: iconState === "ThumbUp" ? "ThumbUpFull" : "ThumbUp" }}
        onClick={() => handleClick("ThumbUp")}
        size="sm"
        variant="secondary"
      />
      <Button
        icon={{
          name: iconState === "ThumbDown" ? "ThumbDownFull" : "ThumbDown",
        }}
        onClick={() => handleClick("ThumbDown")}
        size="sm"
        variant="secondary"
      />
      {isModalOpen && (
        <AiFeedBackModal
          data-color-mode="dark"
          isOpen={isModalOpen}
          closeModal={() => setModalOpen(false)}
          failureCallback={() => setIconState("Default")}
          responseId={responseId}
          thumbState={iconState}
        />
      )}
    </TypographyColor>
  );
};

export const AiFeedBackModal = ({
  isOpen,
  closeModal: closeModalCallback,
  failureCallback,
  responseId,
  thumbState,
}: {
  isOpen: boolean;
  closeModal: () => void;
  failureCallback: () => void;
  responseId: number;
  thumbState: IconState;
}) => {
  const [selectedFeedback, setSelectedFeedback] = useState<Feedback | null>(
    null
  );
  const [feedbackText, setFeedbackText] = useState<string>("");
  const [fieldValid, setFieldValid] = useState({
    other: true,
  });
  const [message, setMessage] = useState<Message | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const feedbackOptions = useMemo<IFeedbackOption[]>(
    () => (thumbState === "ThumbUp" ? positiveFeedback : negativeFeedback),
    [thumbState]
  );
  const feedbackTitle =
    thumbState === "ThumbUp"
      ? "Let us know what was useful"
      : "Can you please specify your feedback on this response to help us improve TheWork.AI ?";

  useEffect(() => {
    if (message) {
      const timeout = setTimeout(() => {
        setMessage(null);
      }, 4000);
      return () => clearTimeout(timeout);
    }
  }, [message]);

  const handleFeedbackChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedFeedback(event.target.value as Feedback);
      if (event.target.value !== "Other") {
        setFeedbackText("");
      }
    },
    []
  );
  const handleTextAreaChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setFeedbackText(event.target.value);
    },
    []
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setFieldValid({ other: true });
      if (!selectedFeedback) return;
      if (selectedFeedback === "Other" && !feedbackText) {
        setFieldValid({ other: false });
        setMessage("OTHER_EMPTY_ERROR");
        return;
      }

      setIsLoading(true);
      try {
        const feedback = {
          curatedResponseId: null,
          feedback: [selectedFeedback],
          feedbackText: feedbackText || "",
          responseId: responseId,
        } as FeedbackData;

        const response = await Api.postFeedback(feedback);
        if (!response) {
          failureCallback();
        }
        closeModalCallback();
      } catch (error) {
        logger.error("Failed to submit feedback:", error);
        failureCallback();
      } finally {
        setIsLoading(false);
      }
    },
    [
      selectedFeedback,
      feedbackText,
      responseId,
      closeModalCallback,
      failureCallback,
    ]
  );

  return (
    <>
      {message ? <MessageFloating {...messages[message]} /> : <></>}
      <Modal
        isOpen={isOpen}
        closeModal={closeModalCallback}
        modalTitle={feedbackTitle}
        colorMode="dark"
      >
        <Typography as="div" size="body-regular">
          <form onSubmit={handleSubmit}>
            <fieldset className="ai-feedback-modal">
              <legend>Choose one option</legend>
              {feedbackOptions.map((option) => (
                <label
                  key={option.feedback}
                  style={{ display: "block", marginBottom: "8px" }}
                >
                  <input
                    type="radio"
                    name="feedback"
                    value={option.feedback}
                    checked={selectedFeedback === option.feedback}
                    onChange={handleFeedbackChange}
                  />
                  {`  ${option.label}`}
                </label>
              ))}
            </fieldset>
            {selectedFeedback === "Other" && (
              <div style={{ margin: "16px 0px" }}>
                <label htmlFor="feedbackText">
                  {fieldValid.other ? (
                    "Please provide additional details:"
                  ) : (
                    <b> Please provide additional details:</b>
                  )}
                </label>
                <textarea
                  id="feedbackText"
                  value={feedbackText}
                  onChange={handleTextAreaChange}
                  rows={4}
                  style={{
                    width: "100%",
                    marginTop: "8px",
                    backgroundColor: "white",
                    color: "black",
                    border: `1px solid ${fieldValid.other ? "black" : "white"}`,
                  }}
                />
              </div>
            )}
            <Button type="submit" disabled={!selectedFeedback || isLoading}>
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
          </form>
        </Typography>
      </Modal>
    </>
  );
};

type Message = "OTHER_EMPTY_ERROR";
const messages: Record<Message, IMessageProps> = {
  OTHER_EMPTY_ERROR: {
    title: "Please provide additional details before submitting.",
    prominence: "inline",
    variant: "error",
  } as IMessageProps,
};
const positiveFeedback: IFeedbackOption[] = [
  { feedback: "Expected", label: "Satifactory" },
  { feedback: "Good", label: "Good" },
  { feedback: "Perfect", label: "Excellent" },
];
const negativeFeedback: IFeedbackOption[] = [
  { feedback: "TooLong", label: "It was too long" },
  { feedback: "TooShort", label: "It was too short" },
  {
    feedback: "Inaccurate",
    label: "It was inaccurate/had incorrect information",
  },
  { feedback: "Irrelevant", label: "It wasn’t relevant to the question" },
  { feedback: "MisMatchedSources", label: "It doesn’t match with the sources" },
  { feedback: "Unclear", label: "The answer wasn’t easy to understand" },
  { feedback: "Other", label: "Other" },
];
