import React, { useCallback, useEffect, useReducer } from "react";
import { AiResponseItem } from "./AiResponseItem";
import { AskResponse } from "@/services/providers/llm/types";
import { IMessageProps, Section } from "@lions/ui-components";
import { Events, PubSubProvider } from "@/libs/pubSub";
import { Api } from "@/libs/api";
import { MessageFloating } from "@/components";
import { Context } from "./AiContext";

type State = {
  responses: AskResponse[];
  savedItems: AskResponse[];
  currentId: number | null;
  message: Messages | null;
};

type Action =
  | { type: "ADD_RESPONSE"; payload: { response: AskResponse } }
  | { type: "SAVE_RESPONSE"; payload: { responseId: number } }
  | { type: "UNSAVE_RESPONSE"; payload: { responseId: number } }
  | { type: "SET_CURRENT_ID"; payload: { responseId: number | null } }
  | { type: "LOAD_SAVED_ITEMS"; payload: { responses: AskResponse[] } }
  | { type: "SET_MESSAGE"; payload: { message: Messages } }
  | { type: "HIDE_MESSAGE" };

const initialState: State = {
  responses: [],
  savedItems: [],
  currentId: null,
  message: null,
};

const responseReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "ADD_RESPONSE": {
      const { response } = action.payload;
      return {
        ...state,
        responses: [response, ...state.responses],
        currentId: response.responseId,
      };
    }
    case "SAVE_RESPONSE": {
      const { responseId } = action.payload;
      const itemToSave = state.responses.find(
        (item) => item.responseId === responseId
      );
      if (!itemToSave) return state;
      return {
        ...state,
        responses: [
          ...state.responses.filter((item) => item.responseId !== responseId),
        ],
        savedItems: [itemToSave, ...state.savedItems],
      };
    }
    case "UNSAVE_RESPONSE": {
      const { responseId } = action.payload;
      const unsavedItem = state.savedItems.find(
        (item) => item.responseId === responseId
      );
      if (!unsavedItem) return state;
      return {
        ...state,
        savedItems: [
          ...state.savedItems.filter((item) => item.responseId !== responseId),
        ],
        responses: [unsavedItem, ...state.responses],
      };
    }
    case "SET_CURRENT_ID": {
      const actionId = action.payload.responseId || null;
      return {
        ...state,
        currentId: actionId === state.currentId ? null : actionId,
      };
    }
    case "LOAD_SAVED_ITEMS": {
      // ensure we dont load twice
      return state.savedItems.length > 0
        ? state
        : {
            ...state,
            savedItems: [...remove_duplicates(action.payload.responses)],
          };
    }
    case "SET_MESSAGE": {
      return { ...state, message: action.payload.message };
    }
    case "HIDE_MESSAGE": {
      return { ...state, message: null };
    }
    default:
      return state;
  }
};

// To deal with a bug on the warc server were it returns duplicate responses
// contentID seems not to be a unique field
const remove_duplicates = (arr: AskResponse[]): AskResponse[] => {
  return arr.reduce<AskResponse[]>((acc, current) => {
    if (!acc.some((item) => item.responseId === current.responseId)) {
      acc.push(current);
    }
    return acc;
  }, []);
};

type Messages = "NO_RESPONSE_ERROR" | "FETCH_SAVED_ITEMS_ERROR";

const messages: Record<Messages, IMessageProps> = {
  NO_RESPONSE_ERROR: {
    title: "Unable to get response",
    prominence: "inline",
    variant: "error",
  } as IMessageProps,
  FETCH_SAVED_ITEMS_ERROR: {
    title: "Unable to fetch saved items",
    prominence: "inline",
    variant: "error",
  } as IMessageProps,
};

export const AiResponseComponent = () => {
  const [state, dispatch] = useReducer(responseReducer, initialState);

  // Subscribe to PubSub events
  PubSubProvider.useSub(Events.AISearch, (askPayload: AskResponse) => {
    if (askPayload.error) {
      dispatch({
        type: "SET_MESSAGE",
        payload: { message: "NO_RESPONSE_ERROR" },
      });
      return;
    }
    dispatch({ type: "ADD_RESPONSE", payload: { response: askPayload } });
  });

  // On mount, load saved items
  useEffect(() => {
    const loadSavedItems = async () => {
      try {
        const savedItems = await Api.getSavedAiResponses();
        if (Array.isArray(savedItems)) {
          dispatch({
            type: "LOAD_SAVED_ITEMS",
            payload: { responses: savedItems as AskResponse[] },
          });
        } else {
          throw new Error("Invalid saved items response");
        }
      } catch (error) {
        dispatch({
          type: "SET_MESSAGE",
          payload: { message: "FETCH_SAVED_ITEMS_ERROR" },
        });
      }
    };

    loadSavedItems();
  }, []);

  // Hide message after 4 seconds
  useEffect(() => {
    if (state.message) {
      const timer = setTimeout(() => {
        dispatch({ type: "HIDE_MESSAGE" });
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [state.message]);

  // Handles saving an item
  const saveResponseCallback = useCallback((responseId: number) => {
    dispatch({ type: "SAVE_RESPONSE", payload: { responseId } });
  }, []);

  // Handles unsaving an item
  const unsaveResponseCallback = useCallback((responseId: number) => {
    dispatch({ type: "UNSAVE_RESPONSE", payload: { responseId } });
  }, []);

  // Handles collapse or expand logic
  const collapseCallback = useCallback((responseId: number) => {
    dispatch({
      type: "SET_CURRENT_ID",
      payload: { responseId: responseId },
    });
  }, []);

  const { responses, savedItems, currentId } = state;

  return (
    <>
      {responses.length > 0 ? (
        <Section
          backgroundColor="background-offwhite"
          contentSpace="spacing-large"
          title="Answers"
          variant="light"
          paddingBottomOverride="spacing-none"
        >
          {state.message ? (
            <MessageFloating {...messages[state.message]} isClosable />
          ) : (
            <></>
          )}

          <AiResponseItems
            title="Answers"
            responses={responses}
            saveCallback={saveResponseCallback}
            unsaveCallback={unsaveResponseCallback}
            collapseCallback={collapseCallback}
            currentId={currentId}
          />
        </Section>
      ) : (
        <Context paddingBottom={savedItems.length ? false : true} />
      )}
      {savedItems.length > 0 && (
        <Section
          backgroundColor="background-offwhite"
          contentSpace="spacing-large"
          title="Saved Answers"
          variant="light"
        >
          <AiResponseItems
            title="Saved"
            responses={savedItems}
            isSavedResponse={true}
            saveCallback={saveResponseCallback}
            unsaveCallback={unsaveResponseCallback}
            collapseCallback={collapseCallback}
            currentId={currentId}
          />
        </Section>
      )}
    </>
  );
};

const AiResponseItems = ({
  responses,
  isSavedResponse,
  saveCallback,
  unsaveCallback,
  collapseCallback,
  currentId,
}: {
  title: string;
  responses: AskResponse[];
  isSavedResponse?: boolean;
  saveCallback: (id: number) => void;
  unsaveCallback: (id: number) => void;
  collapseCallback: (id: number) => void;
  currentId: number | null;
}) => (
  <div className="response-item">
    {responses.map((response) => (
      <AiResponseItem
        {...response}
        isOpen={currentId === response.responseId}
        isSavedResponse={isSavedResponse}
        key={response.responseId}
        saveResponseCallback={isSavedResponse ? undefined : saveCallback}
        showHideCallback={collapseCallback}
        unsaveResponseCallback={isSavedResponse ? unsaveCallback : undefined}
      />
    ))}
  </div>
);
