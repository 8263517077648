import { callClientApi } from "@/libs/client_api_caller";
import { logger } from "@/libs/Logger";
import { AskResponseSource } from "@/services/providers/llm/types";

const GET_SOURCES = "/api/ai/sources";

export const getSources = async (askSources: AskResponseSource[]) => {
  try {
    const nextResponse = await callClientApi(GET_SOURCES, { askSources });
    if (!nextResponse.ok) {
      logger.error("Failed to get sources", nextResponse);
      return null;
    }
    return await nextResponse.json();
  } catch (error) {
    logger.error("Error while posting feedback to Ask API:", error);
    return null;
  }
};
