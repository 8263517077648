import { FilterData, RankingsBlock } from "@/components/components.d";
import { QueryParams } from "@/libs/QueryParams";
import { buildKey } from "../libs";
import {
  Box,
  Button_v2,
  Container,
  Dropdown,
  FilterSelector,
  FlexGroup,
  Spacer,
  Tabs_v2 as Tabs,
} from "@horizon/components";

const tabs = [
  "Special Awards",
  "People",
  "Agencies",
  "Geographies",
  "Brands",
  "Tracks",
];

const onIndexChange = (
  index: number,
  selectedTab: number,
  rankingsBlock: RankingsBlock
) => {
  if (selectedTab === index) return;
  const type = tabs[index];
  const newParams = {
    type: type,
    category: "1",
    year: rankingsBlock?.searchQuery?.year.toString() || "",
  };
  QueryParams.replaceAllQueryParams(newParams);
};

export const RankingsBlockComponent = (rankingsBlock: RankingsBlock) => {
  const { filterOptions, variant, id } = rankingsBlock;

  const type = rankingsBlock.searchQuery?.type;
  const selectedTab = type ? tabs.indexOf(type) : 0;
  const showTabs = variant === "Cannes Lions";

  return (
    <Spacer size={"large"}>
      {showTabs && (
        <Container>
          <Tabs
            key={buildKey("table", 1)}
            tabs={tabs.map((tab) => {
              return { title: tab };
            })}
            selectedIndex={selectedTab}
            onChangeCallBack={(_: string, index: number) =>
              onIndexChange(index, selectedTab, rankingsBlock)
            }
            width={[12]}
          />
        </Container>
      )}
      {/* Not sure this is the right place for the ID */}
      <Container id={id}>
        <FlexGroup gap={["medium", "base"]}>
          {filterOptions?.map((filterData: FilterData, index: number) =>
            buildRankingsFilter(filterData, index)
          )}
        </FlexGroup>
      </Container>
    </Spacer>
  );
};

const buildRankingsFilter = (filterData: FilterData, index: number) => {
  return (
    <Dropdown
      keepOpen
      key={buildKey("filter-dropdown", index)}
      id="dropdown"
      menuItems={filterOptions(filterData)}
      trigger={filterTrigger(filterData.label)}
    />
  );
};

const filterTrigger = (label: string | undefined) => {
  return (
    <Button_v2
      icon={{ name: "ChevronDown", position: "end" }}
      variant="secondary"
    >
      {label || "Options"}
    </Button_v2>
  );
};

const filterOptions = (filterData: FilterData) => {
  const { options, initialValue, key } = filterData;
  return options.map(({ label, value }) => {
    return (
      <Box key={label}>
        <FilterSelector
          variant="filter"
          inputType="radio"
          label={label}
          checked={initialValue?.includes(value)}
          onChangeCallback={() => onOptionChange(key, value)}
        />
      </Box>
    );
  });
};

const onOptionChange = (key: string, value: string) => {
  QueryParams.replaceQueryParam(key, value);
};
