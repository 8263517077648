import { QueryParams } from "@/libs/QueryParams";
import {
  ComponentData,
  ComponentGroupingData,
  PaginationData,
} from "@/renderers";
import { ComponentGrouping } from "@/services/providers/contentful/types";
import { mapComponentsToCards } from "@/services/libs";
import { Pagination } from "@/services/providers/gemini/types";
import { createId } from "@/components/libs";

export const toComponentGroupingData = ({
  autoplayCarousel,
  carouselControlPosition,
  carouselItemsIndicator,
  componentsCollection,
  componentsPerRow,
  link,
  maxRecords,
  noResultsText,
  page,
  pageSize,
  pagination,
  searchQuery,
  title,
  totalRecords,
  variant,
}: ComponentGrouping): ComponentData => {
  let results = {
    type: "ComponentGrouping",
    autoplayCarousel,
    carouselControlPosition,
    carouselItemsIndicator,
    componentsPerRow,
    cta: link ? { label: link.label, href: link.link } : undefined,
    maxRecords,
    noResultsText,
    pagination,
    searchQuery,
    title,
    totalRecords,
    variant,
    id: createId(title),
  } as ComponentGroupingData;
  if (maxRecords && componentsCollection.items) {
    componentsCollection.items = componentsCollection.items.slice(
      0,
      maxRecords
    );
  }
  results.components = mapComponentsToCards(searchQuery, componentsCollection);
  results = maybeAddPagination(
    page,
    pageSize,
    maxRecords,
    totalRecords,
    results,
    componentsPerRow
  );
  results = maybeAddLoadMore(results, variant);
  return results as ComponentData;
};

const maybeAddPagination = (
  page: number | undefined,
  pageSize: number | undefined,
  maxRecords: number | undefined,
  totalRecords: number | undefined,
  results: ComponentGroupingData,
  componentsPerRow: number | number[] | undefined
) => {
  if (isLoadMoreContentType(results.searchQuery)) return results;
  const componentsPerRowCount = Array.isArray(componentsPerRow)
    ? componentsPerRow[componentsPerRow.length - 1]
    : componentsPerRow;
  const pageComparator = componentsPerRowCount || pageSize;
  if (
    pageComparator &&
    results.components &&
    results.components.length < pageComparator
  ) {
    return results;
  }
  if (totalRecords && pageSize && totalRecords < pageSize) return results;
  if (maxRecords && pageSize && maxRecords < pageSize) return results;

  if (page && pageSize && totalRecords) {
    results.page = page;
    results.pageSize = pageSize;
    results.totalRecords = totalRecords;
  }
  return results;
};

const maybeAddLoadMore = (
  results: ComponentGroupingData,
  variant: "grid" | "carousel" | undefined
) => {
  if (isLoadMoreContentType(results.searchQuery) && variant === "grid") {
    results.showLoadMore = true;
    return results;
  }
  return results;
};

const isLoadMoreContentType = (searchQueryString?: string) => {
  const searchQuery = QueryParams.parseSearchQuery(searchQueryString);
  return searchQuery && searchQuery.content_type === "entries";
};

export const toPaginationData = (
  pagination: Pagination | undefined | null
): PaginationData | undefined => {
  if (!pagination) return undefined;
  return {
    page: pagination.currentPage,
    pageSize: pagination.pageSize,
    totalRecords: pagination.totalRecords,
    type: "Pagination",
  };
};
