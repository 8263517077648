import { Section } from "@lions/ui-components";
import { SectionBlockDataUI } from "@/renderers";

export const SectionComponentUI = ({
  sectionData,
  children,
}: {
  sectionData: SectionBlockDataUI;
  children: JSX.Element | JSX.Element[] | null;
}): JSX.Element => {
  const { backgroundColor, title, variant } = sectionData;
  return (
    <Section
      backgroundColor={backgroundColor}
      contentSpace="spacing-large"
      title={title}
      variant={variant}
    >
      {children}
    </Section>
  );
};
