import React from "react";
import { CardText, Section } from "@lions/ui-components";

export const Context = ({ paddingBottom = false }) => (
  <Section
    backgroundColor="background-offwhite"
    contentSpace="spacing-large"
    variant="light"
    paddingBottomOverride={paddingBottom ? "spacing-large" : "spacing-none"}
  >
    <Section backgroundColor="white" variant="light">
      <CardText>
        <span style={{ color: "black", whiteSpace: "pre-line" }}>
          {contextCopy}
        </span>
      </CardText>
    </Section>
  </Section>
);
const contextCopy = `
The Work AI will help you to explore and unlock thousands of award winning campaigns, improving your search experience across the site.
Just ask a question and it will search through our database to uncover the most relevant case studies and examples.
Remember, it’s still a robot, so it might not nail it every time.
Please share your feedback so we can keep tweaking and improving.
`;
