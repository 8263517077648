import { callClientApi, callGetApi } from "@/libs/client_api_caller";
import { logger } from "@/libs/Logger";
import { AskResponse } from "@/services/providers/llm/types";

const ASK_API_URL = "/api/ai/ask";
const SAVE_API_URL = "/api/ai/save";
const UNSAVE_API_URL = "/api/ai/unsave";
const LOAD_RESPONSES = "/api/ai/responses";

export const askAi = async (
  searchText: string
): Promise<AskResponse | null> => {
  try {
    const nextResponse = await callClientApi(ASK_API_URL, {
      question: searchText,
    });
    if (!nextResponse.ok) {
      throw new Error("Failed to fetch response from Ask API");
    }
    return (await nextResponse.json()) as AskResponse;
  } catch (error) {
    logger.error("Error while calling Ask API:", error);
    throw error;
  }
};

export const saveAiResponse = async ({
  responseId,
}: {
  responseId: number;
}) => {
  try {
    const response = await callClientApi(SAVE_API_URL, { responseId });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to save response: ${responseId}`);
    }
  } catch (err: any) {
    logger.error("Error while calling Ask API:", err);
    return null;
  }
};

export const unsaveAiResponse = async ({
  responseId,
}: {
  responseId: number;
}) => {
  try {
    const response = await callClientApi(UNSAVE_API_URL, { responseId });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to unsave response: ${responseId}`);
    }
  } catch (err: any) {
    logger.error("Error while calling Ask API:", err);
    return null;
  }
};

export const getSavedAiResponses = async () => {
  try {
    const response = await callGetApi(LOAD_RESPONSES);
    if (response.ok) {
      const aiResponses = await response.json();
      return aiResponses;
    } else {
      throw new Error("Failed to get saved responses");
    }
  } catch (err: any) {
    logger.error("Error while calling Ask API:", err);
    return null;
  }
};
