import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import { Api } from "@/libs/api";
import { PubSubProvider, Events, SearchQueryResponse } from "@/libs/pubSub";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { mapComponentsToCards } from "@/services/libs";

export const searchContent = ({
  parsedSearchQuery,
}: {
  parsedSearchQuery?: ParsedQuery;
}) => {
  Api.searchContent({ parsedSearchQuery }).then((results) => {
    const { content, pagination } = results;
    const cards = mapComponentsToCards(
      QueryParams.queryToString(parsedSearchQuery),
      {
        items: content,
      }
    );

    PubSubProvider.publishEvent<SearchQueryResponse>(Events.SearchResults, {
      nextResults: cards || [],
      pagination: GeminiMapper.toPaginationData(pagination),
    });
  });
};
