import { callClientApi } from "@/libs/client_api_caller";
import { logger } from "@/libs/Logger";
import { AskResponse, FeedbackData } from "@/services/providers/llm/types";

const FEEDBACK_API_URL = "/api/ai/feedback";

export const postFeedback = async (
  feedback: FeedbackData
): Promise<AskResponse | null> => {
  try {
    const nextResponse = await callClientApi(FEEDBACK_API_URL, feedback);
    if (!nextResponse.ok) {
      logger.error("Failed to post feedback to Ask API", nextResponse);
      return null;
    }
    return (await nextResponse.json()) as AskResponse;
  } catch (error) {
    logger.error("Error while posting feedback to Ask API:", error);
    return null;
  }
};
