import {
  updateCollection,
  deleteCollection,
  shareCollection,
  createCollection,
} from "./collection";
import { trackForm } from "./form";
import { getDigitalSchedule } from "./schedule";
import { getUserData, storeUserSeach } from "./user";
import {
  askAi,
  getSavedAiResponses,
  saveAiResponse,
  getSources,
  postFeedback,
  unsaveAiResponse,
} from "./ai";
import {
  searchContent,
  getSearchSuggestions,
  getSearchHistory,
} from "./search";

export const Api = {
  askAi,
  createCollection,
  deleteCollection,
  getDigitalSchedule,
  getSavedAiResponses,
  getSearchHistory,
  getSearchSuggestions,
  getSources,
  getUserData,
  postFeedback,
  saveAiResponse,
  searchContent,
  shareCollection,
  storeUserSeach,
  trackForm,
  unsaveAiResponse,
  updateCollection,
};
