import React from "react";
import { TypographyColor } from "../TypographyColor";
import { Link } from "@lions/ui-components";

export const Disclaimer = () => (
  <>
    <TypographyColor color="black" size={"label-short"}>
      <Bold>{"Disclaimer: "}</Bold>
      The Work.AI is an AI tool and may generate inaccurate, incomplete or
      misleading responses. We recommend that you use responses as a starting
      point and verify the information in the linked documentation sources.
      Please note it is a text-based search tool.
    </TypographyColor>
    <TypographyColor color="black" size={"label-short"}></TypographyColor>
    <TypographyColor color="black" size={"label-short"}>
      Please see our{" "}
      <Link href="/ai/faq" color="#228181">
        <TypographyColor as="span" color="secondary" size={"label-short"}>
          FAQs{" "}
        </TypographyColor>
      </Link>
      ,{" "}
      <Link href="/ai/policy">
        <TypographyColor as="span" color="secondary" size={"label-short"}>
          AI Policy{" "}
        </TypographyColor>
      </Link>
      , and{" "}
      <Link href="/ai/terms-of-use">
        <TypographyColor as="span" color="secondary" size={"label-short"}>
          Terms of Use
        </TypographyColor>
      </Link>
      .
    </TypographyColor>
  </>
);

const Bold = ({ children }: { children: string }) => (
  <span style={{ fontWeight: "bold" }}>{children}</span>
);
