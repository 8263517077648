import {
  AwardTagVariant,
  PageTitleBlockData,
  TabContainerData,
  ComponentData,
  ComponentGroupingData,
  TextData,
  SaveCollectionButtonData,
  GalleryCarouselData,
  CaptionData,
  HeroTitleData,
  TableDataNew,
} from "@/renderers";
import { campaignToCard } from "@/services/libs";
import {
  Award,
  AwardLevel,
  Campaign,
  CampaignEntry,
  CampaignEntryType,
  Collection,
} from "@/services/providers/gemini/types";
import {
  PATHS,
  buildContainerData,
  buildSpacerData,
  defaultComponentsPerRowCarousel,
  listToCommaSeparatedString,
  typeFromAsset,
} from "@/services/libs";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { MarkdownData } from "@/components/components.d";
import {
  awardLevelToVariant,
  awardTagLevelValues,
  awardToTagDataWithCount,
  sortByAwardLevel,
} from "@/services/libs/award_utils";
import { UserAction } from "@/libs/authentication/user";
import { toExecutionTab } from "@/services/work-awards/entry_show/entry_show_mapper";
import {
  buildHeaderCell,
  buildLabelCell,
  buildLinkCell,
} from "@/services/libs/table_utils";
import { ITableCell, ITableRow } from "@ui-components";

const toTitleData = (
  campaign: Campaign,
  collections: Collection[]
): PageTitleBlockData => {
  const { brand, company, festival, title, year } = campaign;
  return {
    type: "PageTitleBlock",
    title: title,
    superText: festival.name,
    subText:
      listToCommaSeparatedString([company?.name, company?.town]) +
      ` / ${brand?.name} / ${year}`,
    awardTags: campaignToAwards(campaign)
      .sort(sortByAwardLevel)
      .map(awardToTagDataWithCount),
    saveCollectionButtonData: toCollectionsSaveButton(campaign, collections),
  };
};

const campaignToAwards = (campaign: Campaign): Award[] => {
  const awardsMap = new Map<AwardLevel, Award>();
  const awardSum = (campaign.entryTypes || []).reduce(
    reduceEntryTypesToAwards,
    awardsMap
  );
  return Array.from(awardSum.values());
};

const reduceEntryTypesToAwards = (
  awardsMap: Map<AwardLevel, Award>,
  entryType: Partial<CampaignEntryType>
): Map<AwardLevel, Award> => {
  (entryType.entries || []).reduce(reduceEntriesToAwards, awardsMap);
  return awardsMap;
};

const reduceEntriesToAwards = (
  awardsMap: Map<AwardLevel, Award>,
  entry: CampaignEntry
): Map<AwardLevel, Award> => {
  const { level, levelLabel } = entry;
  if (level) {
    awardsMap.has(level)
      ? (awardsMap.get(level)!.count! += 1)
      : awardsMap.set(level, { level, levelLabel, count: 1 });
  }
  return awardsMap;
};

const toCollectionsSaveButton = (
  campaign: Campaign,
  collections?: Collection[]
): SaveCollectionButtonData | undefined => {
  if (!campaign.saveable) return;
  return {
    type: "SaveCollectionButton",
    collections: collections?.map(GeminiMapper.toCollectionData),
    contentType: "campaign",
    contentId: campaign.id.toString(),
  };
};

const toGalleryCarousel = (
  { assets }: Campaign,
  userActions?: UserAction[]
): GalleryCarouselData => ({
  type: "GalleryCarousel",
  userActions,
  media: assets.map((asset) => ({
    alt: asset.label || "",
    href: asset.fullUrl || "",
    id: asset.id as number,
    mediaType: typeFromAsset(asset),
    thumbnail: asset.thumbnailUrl,
    downloadable: asset.downloadable,
  })),
});

const maybeToGalleryCarousel = (
  campaign: Campaign,
  userActions?: UserAction[]
): GalleryCarouselData | null => {
  return campaign.assets.length
    ? toGalleryCarousel(campaign, userActions)
    : null;
};

const toTabContainer = (campaign: Campaign): TabContainerData => ({
  type: "TabContainer",
  reloadOnTabChange: false,
  tabs: [
    {
      type: "Tab",
      title: "Overview",
      label: "Overview",
      components: [buildContainerData(toOverviewTab(campaign))],
    },
    {
      type: "Tab",
      title: "Entries",
      label: "entries",
      components: [
        buildContainerData([buildSpacerData(toEntriesTab(campaign))]),
      ],
    },
    {
      type: "Tab",
      title: "Credits",
      label: "credits",
      components: [
        buildContainerData([buildSpacerData(toCreditsTab(campaign))]),
      ],
    },
  ],
});

const toOverviewTab = ({ caseStudy }: Campaign): ComponentData[] => {
  let content: (HeroTitleData | TextData | CaptionData | MarkdownData)[] = [
    {
      type: "HeroTitle",
      content: "OVERVIEW",
      size: "medium",
    },
  ];

  const maybeAddToArray = (
    accumulator: (HeroTitleData | TextData | CaptionData | MarkdownData)[],
    title: string,
    content: string | null
  ): (HeroTitleData | TextData | CaptionData | MarkdownData)[] => {
    if (content) {
      accumulator.push({
        type: "Caption",
        content: title,
        size: "medium",
      });
      accumulator.push({
        type: "Markdown",
        html: content
          ?.split("\n")
          .filter(Boolean)
          .map((str) => `<p>${str}</p>`)
          .join(""),
        copySize: "medium",
      });
    }
    return accumulator;
  };

  if (caseStudy) {
    content = maybeAddToArray(content, "Background", caseStudy.background);
    content = maybeAddToArray(content, "Idea", caseStudy.idea);
    content = maybeAddToArray(content, "Strategy", caseStudy.strategy);
    content = maybeAddToArray(content, "Description", caseStudy.description);
    content = maybeAddToArray(content, "Execution", caseStudy.execution);
    content = maybeAddToArray(content, "Outcome", caseStudy.outcome);
  }

  return [
    {
      type: "Spacer",
      size: "medium",
      content,
    } as ComponentData,
  ];
};

const sortEntriesByLevel = (a: ITableRow, b: ITableRow): number => {
  const entries = [a, b].map((entry): number => {
    const cell = entry.cells[3];
    const tagVariant =
      "tag" in cell && cell.tag ? (cell.tag.variant as AwardTagVariant) : null;
    return tagVariant ? awardTagLevelValues[tagVariant] : 0;
  });
  return entries[0] <= entries[1] ? 1 : -1;
};

const sortExecutionsByLevel = (a: CampaignEntry, b: CampaignEntry): number => {
  const entries = [a, b].map((entry): number => {
    const tagVariant = entry?.prizeLevel as AwardTagVariant;
    return tagVariant ? awardTagLevelValues[tagVariant] : 0;
  });
  return entries[0] <= entries[1] ? 1 : -1;
};

interface ExecutionAndCampaignDataI {
  campaign: CampaignEntry[];
  executions: Record<string, CampaignEntry[]>;
}
/**
 * we might have multiple campaigns of execution
 * this need to go before the campaign
 */
export const createExecutionsAndCampaignData = (
  entries: CampaignEntry[]
): ExecutionAndCampaignDataI => {
  const acc: ExecutionAndCampaignDataI = { campaign: [], executions: {} };
  const mappedData = entries.reduce((acc, entry: CampaignEntry) => {
    const campaignOfExecutionId = Reflect.get(entry, "campaignOfExecutionId");
    if (campaignOfExecutionId) {
      if (!Reflect.has(acc.executions, campaignOfExecutionId)) {
        Reflect.set(acc.executions, campaignOfExecutionId, []);
      }

      const executionEnt = Reflect.get(acc.executions, campaignOfExecutionId);
      executionEnt.push(entry);
      return acc;
    } else {
      acc.campaign.push(entry);
      return acc;
    }
  }, acc);
  return mappedData;
};

const toEntriesTab = ({ entryTypes }: Campaign): TableDataNew[] => {
  if (!entryTypes) {
    return [];
  }

  let aggregated: TableDataNew[] = [];
  entryTypes.forEach((entryType): void => {
    if (!entryType.entries) {
      aggregated.push({} as TableDataNew);
      return;
    }

    const localTables = [];
    const executionAndCampaignData = createExecutionsAndCampaignData(
      entryType.entries
    );
    if (Reflect.ownKeys(executionAndCampaignData.executions)) {
      Object.values(executionAndCampaignData.executions).forEach((entries) => {
        entries.sort(sortExecutionsByLevel);
        const executionTable = toExecutionTab(entries, null);
        Reflect.set(executionTable[0], "title", "");
        localTables.push(executionTable[0]);
      });
    }

    const rows = executionAndCampaignData.campaign.map(
      (entry): ITableRow => ({
        cells: [
          buildLinkCell(entry.title, `${PATHS.entries}/${entry.slug}`),
          buildLabelCell(entry.section),
          buildLabelCell(entry.category),
          buildTagCell(entry),
        ],
      })
    );

    if (rows.length > 0) {
      rows.sort(sortEntriesByLevel);
      localTables.push({
        type: "TableNew",
        title: "",
        tableData: {
          headers: [
            buildHeaderCell("Name"),
            buildHeaderCell("Section"),
            buildHeaderCell("Category"),
            buildHeaderCell("Awards"),
          ],
          rows: rows,
        },
      });
    }
    // need to set the first above the first table
    // the later ones don't need a title
    localTables[0].title = entryType?.name || "";

    aggregated = [...aggregated, ...localTables] as TableDataNew[];
  });

  return aggregated;
};
const buildTagCell = (entry: CampaignEntry): ITableCell => {
  const variant = awardLevelToVariant(entry.level as AwardLevel);

  return variant
    ? {
        variant: "tag",
        tag: { text: entry.levelLabel, variant },
      }
    : {
        variant: "label",
        label: { text: "" },
      };
};

const toCreditsTab = (campaign: Campaign): TableDataNew[] => {
  const companies = campaign.credits?.companies?.map((company) => ({
    cells: [
      company.slug
        ? buildLinkCell(company.name, `${PATHS.agencies}/${company.slug}`)
        : buildLabelCell(company.name),
      buildLabelCell(company.location),
      buildLabelCell(company.role),
    ],
  }));
  const people = campaign.credits?.people?.map((person) => ({
    cells: [
      person.slug
        ? buildLinkCell(person.name, `${PATHS.people}/${person.slug}`)
        : buildLabelCell(person.name),
      buildLabelCell(person.company),
      buildLabelCell(person.role),
    ],
  }));

  return [
    campaign.credits?.companies && {
      type: "TableNew",
      title: "Companies",
      tableData: {
        headers: [
          buildHeaderCell("Company"),
          buildHeaderCell("Location"),
          buildHeaderCell("Role"),
        ],
        rows: companies,
      },
    },
    campaign.credits?.people && {
      type: "TableNew",
      title: "People",
      tableData: {
        headers: [
          buildHeaderCell("Name"),
          buildHeaderCell("Company"),
          buildHeaderCell("Role"),
        ],
        rows: people,
      },
    },
  ].filter(Boolean) as TableDataNew[];
};

const toRelatedCampaigns = (
  campaigns: [Campaign] | []
): ComponentGroupingData => ({
  type: "ComponentGrouping",
  title: "Similar Campaigns",
  variant: "carousel",
  components: campaigns.map((campaign: Campaign): ComponentData => {
    return campaignToCard(campaign);
  }),
  componentsPerRow: defaultComponentsPerRowCarousel,
});

export const CampaignShowMapper = {
  toTitleData,
  maybeToGalleryCarousel,
  toTabContainer,
  toRelatedCampaigns,
};
