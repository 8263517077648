import React, { useReducer, useEffect, useCallback } from "react";
import {
  Button,
  Box,
  Collapsible,
  type IMessageProps,
  Flex,
  Section,
} from "@lions/ui-components";
import type {
  AskResponseItem,
  AskResponseSource,
} from "@/services/providers/llm/types";
import { Api } from "@/libs/api";
import { TypographyColor } from "../TypographyColor";
import { MessageFloating } from "@/components";
import { useDebounce } from "@/libs/hooks/useDebounce";
import { Sources } from "./AiSourceCard";
import { useMediaQuery } from "@/libs/hooks";
import { Content } from "./AiContent";

const MAX_SOURCES = parseInt(process.env.MAX_SOURCES || "0");

type State = {
  isSaved: boolean;
  isSaving: boolean;
  sources: AskResponseSource[];
  message: null | Messages;
};
type Action =
  | {
      type:
        | "SAVE_START"
        | "SAVE_FINISH"
        | "SAVE_SUCCESS"
        | "UNSAVE_SUCCESS"
        | "HIDE_MESSAGE";
    }
  | { type: "SET_SOURCES"; payload: { sources: AskResponseSource[] } }
  | { type: "SET_MESSAGE"; payload: { message: Messages } };

const initialState: State = {
  isSaved: false,
  isSaving: false,
  message: null,
  sources: [],
};

const responseReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SAVE_START":
      return { ...state, isSaving: true };
    case "SAVE_FINISH":
      return { ...state, isSaving: false };
    case "SAVE_SUCCESS":
      return { ...state, isSaved: true, isSaving: false };
    case "UNSAVE_SUCCESS":
      return { ...state, isSaved: false, isSaving: false };
    case "SET_SOURCES":
      return { ...state, sources: action.payload.sources };
    case "HIDE_MESSAGE":
      return { ...state, message: null };
    case "SET_MESSAGE": {
      return { ...state, message: action.payload.message };
    }

    default:
      return state;
  }
};

type Messages =
  | "ERROR"
  | "SAVE_ERROR"
  | "UNSAVE_ERROR"
  | "FETCH_SOURCES_ERROR"
  | "UNKNOWN_ERROR";

const messages = {
  ERROR: {
    title: "Unable to remove response from saved list",
    prominence: "inline",
    variant: "error",
  } as IMessageProps,
  SAVE_ERROR: {
    title: "Unable to save response",
    prominence: "inline",
    variant: "error",
  } as IMessageProps,
  UNSAVE_ERROR: {
    title: "Unable to unsave response",
    prominence: "inline",
    variant: "error",
  } as IMessageProps,
  FETCH_SOURCES_ERROR: {
    title: "Unable to fetch sources",
    prominence: "inline",
    variant: "error",
  } as IMessageProps,
  UNKNOWN_ERROR: {
    title: "An unexpected error occurred",
    prominence: "inline",
    variant: "error",
  } as IMessageProps,
};

export const AiResponseItem = ({
  responseId,
  content,
  question,
  saveResponseCallback = () => {},
  unsaveResponseCallback = () => {},
  isSavedResponse = false,
  isOpen,
  showHideCallback = () => {},
  sources,
}: AskResponseItem) => {
  const [state, dispatch] = useReducer(responseReducer, {
    ...initialState,
    isSaved: isSavedResponse,
  });

  const isDesktop = useMediaQuery("(min-width: 768px)");
  const iconName = state.isSaved ? "BookmarkFull" : "Bookmark";
  const accessibilityLabel = state.isSaved
    ? "Un-save this response"
    : "Save this response";

  // Toggle Save/Unsave of respone
  const handleSaveCallback = useCallback(async () => {
    dispatch({ type: "SAVE_START" });
    try {
      if (state.isSaved) {
        const result = await Api.unsaveAiResponse({ responseId });
        if (!result) {
          throw new Error("UNSAVE_ERROR");
        }
        dispatch({ type: "UNSAVE_SUCCESS" });
        unsaveResponseCallback(responseId);
      } else {
        const result = await Api.saveAiResponse({ responseId });
        if (!result) {
          throw new Error("SAVE_ERROR");
        }
        dispatch({ type: "SAVE_SUCCESS" });
        saveResponseCallback(responseId);
      }
    } catch (error) {
      const message =
        error instanceof Error && Object.keys(messages).includes(error.message)
          ? error.message
          : "UNKNOWN_ERROR";

      dispatch({
        type: "SET_MESSAGE",
        payload: { message: message as Messages },
      });
    } finally {
      dispatch({ type: "SAVE_FINISH" });
    }
  }, [responseId, state.isSaved, saveResponseCallback, unsaveResponseCallback]);
  const handleSaveCallbackDebounced = useDebounce(handleSaveCallback, 500);

  // Hide message after 4 seconds
  useEffect(() => {
    const fetchSources = async () => {
      try {
        if (sources?.length) {
          const resolvedSources = await Api.getSources(sources);
          dispatch({
            type: "SET_SOURCES",
            payload: { sources: resolvedSources.filter(Boolean) },
          });
        }
      } catch (error) {
        dispatch({
          type: "SET_MESSAGE",
          payload: { message: "FETCH_SOURCES_ERROR" },
        });
      }
    };
    fetchSources();
  }, [sources]);

  // Hide message after 4 seconds
  useEffect(() => {
    if (state.message) {
      const timer = setTimeout(() => {
        dispatch({ type: "HIDE_MESSAGE" });
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [state.message]);

  return (
    <Section
      backgroundColor="background-white"
      contentSpace="spacing-none"
      variant="light"
    >
      <Collapsible
        header={
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <TypographyColor color="black" size="heading-small">
              {capitalize(question)}
            </TypographyColor>
            <Button
              loading={state.isSaving}
              variant={state.isSaved ? "primary" : "secondary"}
              icon={{ name: iconName, position: "end" }}
              accessibility={{ label: accessibilityLabel }}
              onClick={handleSaveCallbackDebounced}
            />
          </Box>
        }
        onClick={() => showHideCallback(responseId)}
        isOpen={isOpen}
      >
        <Box>
          {state.message && (
            <MessageFloating {...messages[state.message]} isClosable />
          )}
        </Box>
        <Flex
          flexDir={isDesktop ? "row" : "column"}
          style={{ marginTop: "24px" }}
        >
          <Content responseId={responseId} content={content} />
          {
            // Check that some sources have content
            state.sources.some((source) => source.content) ? (
              // Determines how many sources to display
              <Sources
                sources={
                  MAX_SOURCES > 0
                    ? state.sources.slice(0, MAX_SOURCES - 1)
                    : state.sources
                }
              />
            ) : (
              <></>
            )
          }
        </Flex>
      </Collapsible>
    </Section>
  );
};

const capitalize = (word: string) =>
  word ? word.charAt(0).toUpperCase() + word.slice(1) : word;
